import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import desktopImage from "../img/WanderOutWildLogo2.jpg";
import mobileImage from "../img/WanderOutWildSmall.jpg";

const LandingPage = () => {
  console.log(window.innerWidth);
  const logo = useWindowWidth() >= 650 ? desktopImage : mobileImage;
  const altLogoText = "Wander out Wild logo";

  return (
    <>
    <div className="error-container">
      <div className="error-image">
        <img src={logo} alt={altLogoText} />
      </div>
      <div className="error-text">
        <h1>Coming Soon</h1>
      </div>
      <footer>
        <p>Copyright &copy; Wander Out Wild 2023</p>
      </footer>
    </div>
      </>
  );
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return windowWidth;
};

export default LandingPage;
